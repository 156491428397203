button.modal-btn.primary.approved {
  padding: 8px 32px;

  border: 0px solid;
  border-radius: 8px;

  font-size: 14px;

  font-weight: 500;
  color: #FFF;

  background-color: #0094FF;

  transition: 0.5s;

  &:hover {
    background-color: #0095E8;
  }
}

button.modal-btn.primary.reproved {
  padding: 8px 32px;

  border: 0px solid;
  border-radius: 8px;

  font-size: 14px;

  font-weight: 500;
  color: #FFF;

  background-color: #F1416C;

  transition: 0.5s;

  &:hover {
    background-color: #F71963;
  }
}

button.modal-btn.primary.success {
  padding: 8px 32px;

  border: 0px solid;
  border-radius: 8px;

  font-size: 14px;

  font-weight: 500;
  color: #FFF;

  background-color: #50CD89;

  transition: 0.5s;

  &:hover {
    background-color: #48bc7c;
  }
}

button.modal-btn.outlined {
  padding: 8px 32px;

  border: 0px solid;
  border-radius: 8px;

  font-size: 14px;

  color: #00A3FF;
  border: 1px solid #00A3FF;
  background-color: #FFF;

  transition: 0.5s;

  &:hover {
    color: #009EF6;
    border: 1px solid #009EF6;
  }
}