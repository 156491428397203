.buttonIA {
  margin-top: 5px;
  background: rgb(93, 95, 239);
  border: none;
  color:#fff;
  font-weight: 700;
  padding:5px 10px;
  border-radius: 4px;

  display:flex;
  align-items: center;
  justify-content: center;
  gap:10px;

  
}

.buttonIA:disabled{
  background: rgb(132, 134, 236);
}

.menuDropIA{
  background-color: #0d6efd;
  margin-top: 5px;
  border: none;
  color:#fff;
  font-weight: 600;
  padding:5px 10px;
  border-radius: 4px;
  position:relative ;
}

.dot {
  width: 8px;
  height: 8px;
  background-color: #bbb;
  border-radius: 50%;
}

.interest {
  margin: 0;
}

.interestWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: 10px !important;
}

.menuDropItensIA{
  top: 1;
  bottom: 1;
  position:absolute ;
  z-index: 999;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
}
.containerIA{
  display: flex;
  gap: 10px;
}
.input{
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  border: 4px;
  &:hover{
    background:#F3F6F9;
  }
}

.label{
  color:#131628;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
}