.bg-catalyst {
  height: 100vh;
}

.bg-blue {
  background: #00A3FF;
}

.text-blue {
  color: #009EF7;
}

.bg-purple {
  background: #e5d7ed;
}

.bg-violet {
  background: #A5A6F6;
}

.bg-white {
  background: #ffffff;
  border: 1px solid #fff;
}

.bg-ligth-blue {
  background: #f1faff;
}

.border-dashed-ligth-blue {
  border-color: #009EF7;
  border-style: dotted;
}

.needs-validation input,
.needs-validation textarea {
  border: 1px solid #dc3545;
}

.text-descriptions {
  overflow: hidden;
  text-overflow: clip;
  white-space: normal;
}

/* Menu */
.border-menu {
  border: 1px solid #2D2D43;
}

.aside.aside-dark .aside-logo {
  background-color: #1e1e2d !important;
  padding: 15px 25px !important;
}

.px-25 {
  padding-left: 25px;
  padding-right: 25px;
}

.select-menu {
  position: relative;
  background-color: #2C2C41;
  color: #fff;
  padding: 15px;
  border: 1px solid #2C2C41;
  border-radius: 10px;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 172 172'%3E%3Cg fill='none' fill-rule='nonzero' stroke='none' stroke-width='1' stroke-linecap='butt' stroke-linejoin='miter' stroke-miterlimit='10' stroke-dasharray='' stroke-dashoffset='0' font-family='none' font-weight='none' font-size='none' text-anchor='none' style='mix-blend-mode: normal'%3E%3Cpath d='M0,172v-172h172v172z' fill='none'%3E%3C/path%3E%3Cg fill='%23ffffff'%3E%3Cpath d='M82.4525,106.15625c2.03758,1.81429 5.11117,1.81429 7.14875,0l48.375,-43c2.2264,-1.97407 2.43095,-5.37923 0.45687,-7.60562c-1.97407,-2.2264 -5.37923,-2.43095 -7.60563,-0.45688l-44.8275,39.82875l-44.8275,-39.82875c-2.2264,-1.97407 -5.63155,-1.76952 -7.60562,0.45688c-1.97407,2.2264 -1.76952,5.63155 0.45687,7.60562z'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
  background-size: 24px;
}

.select-menu:focus,
.select-menu:focus-visible {
  outline: none !important;
}


.connectores-box-connect-api {
  background-color: rgba(210, 201, 197, 0.3);
  margin: auto;
}

.conectors-box {
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;
}

.img {
  width: 100%;
  position: absolute;
}

.conectors-box-connect:hover img,
.conectors-box.active img {
  cursor: pointer;
  -webkit-filter: grayscale(0%);
  /* Safari 6.0 - 9.0 */
  filter: grayscale(0%);
  opacity: 0.8;
}


/*
.conectors-box:hover, .conectors-box.active {
    cursor: pointer;
    -webkit-filter: grayscale(0%);
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    opacity: 0.5;
}


.conectors-box:hover,
.conectors-box.active {
    cursor: pointer;
    -webkit-filter: grayscale(0%);
    filter: grayscale(0%);
    opacity: 0.8;
}
*/

.conectors-box img {
  max-width: 100%;
  -webkit-filter: grayscale(100%);
  /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  opacity: 0.5;
}

.inputLogin {
  padding: 5px;
  width: 230px;
  border-radius: 8px;
  border: none;
  outline: none;
  background: #F2F2F2;
  box-sizing: border-box;
  font-size: 20px;
  text-align: center;
}


.search {
  color: #45abfc;
  font-size: 25px;
  position: absolute;
  left: 0;
  margin-top: 5px;
  margin-left: 10px;
  margin-right: 10px;
  z-index: 2;
}

.conteiner-conectores-search {
  top: -30px;
  width: 60%;
  max-width: 725px;

}

.input-conectores-search {
  background: #fff !important;
  filter: drop-shadow(0px 4px 13px rgba(141, 141, 141, 0.15)) !important;
  height: 59px;
  padding-left: 50px;
}

.grid-conectores {
  padding: 0 30px 30px 30px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

@media (min-width: 525px) {
  .grid-conectores {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 725px) {
  .grid-conectores {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1225px) {
  .grid-conectores {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 1225px) {
  .grid-conectores {
    grid-template-columns: repeat(4, 1fr);
  }
}


.searchApi {
  color: #DADADA;
  font-size: 25px;
  position: absolute;
  left: 0;
  margin-top: -11px;
  margin-left: 10px;
  margin-right: 10px;
  z-index: 2;
}

.conectors-box-connect .sucess {
  width: 90%;
  height: 5px;
  border-radius: 10px;
  background: green;
  z-index: 1;
  position: absolute;
  margin-top: 105px;
  content: "";
}

.error {
  width: 100%;
  height: 5px;
  border-radius: 10px;
  background: red;
  z-index: 2;
  position: absolute;
  margin-top: 120px;
  content: "";
}

.status {
  background: #E8FFF3;
  color: #50CD89;
  text-align: center;
  border-radius: 10px;
  padding: 5px;
}

.custom-dropdown-conectores {
  position: absolute;
  top: 0;
  right: 0;
}

.dropdown-menu-conctores {
  background: #F3F6F9 !important;
  box-shadow: 0px 4px 24px rgba(181, 181, 181, 0.25) !important;
  border-radius: 12px !important;
  padding: 20px 30px;
  z-index: 99;
}

.dropdown-menu-conctores .dropdown-item {
  margin-bottom: 10px;
  background: #EFF2F5;
  border-radius: 12px;
  padding: 10px 20px !important;
}

.dropdown-menu-conctores .dropdown-item.active,
.dropdown-item:active {
  color: #1e1e2d !important;
  background: #fff;
}

.settingsConnectors {
  z-index: 9;
  font-size: 20px;
  background: #dad8d8;
  border-radius: 5px;
  background: #EBEEEF;
  color: #D0D2D4;
}

.custom-dropdown-conectores button::after {
  display: none;
}

.form-check.form-check-info .form-check-input:checked {
  background-color: #7239ea;
}

/* Paginas de Registro  */
.h1-register {
  font-size: 35px;
}

.grid-register {
  display: grid;
  grid-template-columns: 1fr 1.1fr;
  align-items: center;
  align-content: center;
}

.header-fixed .header {
  background: #fff !important;
  position: relative;
  left: 0 !important;
}

.breadcrumb .breadcrumb-item:after {
  color: #a1a5b7;
}

/* Utilits */
.h-100vh {
  height: 100vh;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  background-color: rgba(80, 205, 137, 0.07) !important;
  border: none !important;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-color: rgb(220, 53, 69, 0.07);
  border: none !important;
}

.disabled {
  opacity: 0.6;
  pointer-events: none;
}

.span[role="alert"] {
  color: #dc3545 !important;
}



/* ActionsPlan */
.inputSeacth {
  color: #ccc;
  padding: 0.75rem 3rem !important;
}


@media (max-height: 990px) {
  .cadastroMinHeight {
    display: block;
    overflow: auto;
  }
}

.form-outer {
  width: 100%;
  overflow: hidden;
}

.form-outer form {
  display: flex;
  width: 200%;
}

.form-outer form .form-page {
  width: 50%;
  transition: margin-left .3s ease-in-out;
}

.ml--50 {
  margin-left: -50%;
}

.mr--50 {
  margin-right: -50%;
}



/* Modal */

.btn-close {
  background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzciIGhlaWdodD0iMzciIHZpZXdCb3g9IjAgMCAzNyAzNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggb3BhY2l0eT0iMC4yNSIgZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0zLjg1ODE4IDEwLjMxMjhDNC4yOTMzNyA3LjAxNzU2IDYuODIyMjUgNC40ODg2OSAxMC4xMTc0IDQuMDUzNDlDMTIuMzgyOCAzLjc1NDMgMTUuMjE2NiAzLjUgMTguMzA0NyAzLjVDMjEuMzkyOCAzLjUgMjQuMjI2NiAzLjc1NDMgMjYuNDkxOSA0LjA1MzQ5QzI5Ljc4NzEgNC40ODg2OSAzMi4zMTYgNy4wMTc1NyAzMi43NTEyIDEwLjMxMjhDMzMuMDUwNCAxMi41NzgxIDMzLjMwNDcgMTUuNDExOSAzMy4zMDQ3IDE4LjVDMzMuMzA0NyAyMS41ODgxIDMzLjA1MDQgMjQuNDIxOSAzMi43NTEyIDI2LjY4NzJDMzIuMzE2IDI5Ljk4MjQgMjkuNzg3MSAzMi41MTEzIDI2LjQ5MTkgMzIuOTQ2NUMyNC4yMjY2IDMzLjI0NTcgMjEuMzkyOCAzMy41IDE4LjMwNDcgMzMuNUMxNS4yMTY2IDMzLjUgMTIuMzgyOCAzMy4yNDU3IDEwLjExNzQgMzIuOTQ2NUM2LjgyMjI1IDMyLjUxMTMgNC4yOTMzNyAyOS45ODI0IDMuODU4MTggMjYuNjg3MkMzLjU1ODk5IDI0LjQyMTkgMy4zMDQ2OSAyMS41ODgxIDMuMzA0NjkgMTguNUMzLjMwNDY5IDE1LjQxMTkgMy41NTg5OSAxMi41NzgxIDMuODU4MTggMTAuMzEyOFoiIGZpbGw9IiMwMEEzRkYiLz4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMi43NDQgMTIuOTM5M0MxMy4zMjk4IDEyLjM1MzYgMTQuMjc5NiAxMi4zNTM2IDE0Ljg2NTMgMTIuOTM5M0wxOC4zMDQ3IDE2LjM3ODdMMjEuNzQ0IDEyLjkzOTNDMjIuMzI5OCAxMi4zNTM2IDIzLjI3OTYgMTIuMzUzNiAyMy44NjUzIDEyLjkzOTNDMjQuNDUxMSAxMy41MjUxIDI0LjQ1MTEgMTQuNDc0OSAyMy44NjUzIDE1LjA2MDdMMjAuNDI2IDE4LjVMMjMuODY1MyAyMS45MzkzQzI0LjQ1MTEgMjIuNTI1MSAyNC40NTExIDIzLjQ3NDkgMjMuODY1MyAyNC4wNjA3QzIzLjI3OTYgMjQuNjQ2NCAyMi4zMjk4IDI0LjY0NjQgMjEuNzQ0IDI0LjA2MDdMMTguMzA0NyAyMC42MjEzTDE0Ljg2NTMgMjQuMDYwN0MxNC4yNzk2IDI0LjY0NjQgMTMuMzI5OCAyNC42NDY0IDEyLjc0NCAyNC4wNjA3QzEyLjE1ODIgMjMuNDc0OSAxMi4xNTgyIDIyLjUyNTEgMTIuNzQ0IDIxLjkzOTNMMTYuMTgzNCAxOC41TDEyLjc0NCAxNS4wNjA3QzEyLjE1ODIgMTQuNDc0OSAxMi4xNTgyIDEzLjUyNTEgMTIuNzQ0IDEyLjkzOTNaIiBmaWxsPSIjMDBBM0ZGIi8+Cjwvc3ZnPgo=');
  background-repeat: no-repeat;
  margin: 0.5rem;
  color: #A5A6F6;
}

.btn-message-close {
  background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNSAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggb3BhY2l0eT0iMC41IiBkPSJNMTUuMDE2MSA3Ljc1NzQyTDcuOTQ1MDggMTQuODI4NUM3LjU1NDU2IDE1LjIxOSA3LjU1NDU2IDE1Ljg1MjIgNy45NDUwOCAxNi4yNDI3QzguMzM1NjEgMTYuNjMzMiA4Ljk2ODc3IDE2LjYzMzIgOS4zNTkzIDE2LjI0MjdMMTYuNDMwNCA5LjE3MTY0QzE2LjgyMDkgOC43ODExMSAxNi44MjA5IDguMTQ3OTUgMTYuNDMwNCA3Ljc1NzQzQzE2LjAzOTggNy4zNjY5IDE1LjQwNjcgNy4zNjY5IDE1LjAxNjEgNy43NTc0MloiIGZpbGw9IiMwMDlFRjciLz4KPHBhdGggZD0iTTE2LjQzMDQgMTQuODI4NUw5LjM1OTMzIDcuNzU3NEM4Ljk2ODggNy4zNjY4OCA4LjMzNTY0IDcuMzY2ODggNy45NDUxMSA3Ljc1NzRDNy41NTQ1OSA4LjE0NzkyIDcuNTU0NTkgOC43ODEwOSA3Ljk0NTExIDkuMTcxNjFMMTUuMDE2MiAxNi4yNDI3QzE1LjQwNjcgMTYuNjMzMiAxNi4wMzk5IDE2LjYzMzIgMTYuNDMwNCAxNi4yNDI3QzE2LjgyMDkgMTUuODUyMiAxNi44MjA5IDE1LjIxOSAxNi40MzA0IDE0LjgyODVaIiBmaWxsPSIjMDA5RUY3Ii8+Cjwvc3ZnPgo=');
  background-repeat: no-repeat;
  color: #A5A6F6;
}

.modal label {
  font-weight: 700;
}

/*  */

.p-5-18 {
  padding: 5px 18px !important;
}

.auth-container {
  padding: 100px 131px;
}

.logo-adapt {
  width: auto;
}

img {
  max-width: 100%;
  width: fit-content;
}

/* Menu */
.text-violeta-300 {
  color: #646581;
}

.bg-violeta-800 {
  background: #28283B;
}

.menu-item .menu-link {
  background: #28283B;
  border-radius: 10px;

}

.aside-menu .menu-item .menu-link {
  padding: 12px 20px;
}

.aside-menu .menu-item {
  margin-bottom: 10px;
}

.aside-dark .menu .menu-item .menu-link.active {
  background: #5D5FEF;
}

.aside-dark .menu .menu-item .menu-link.active .menu-icon,
.aside-dark .menu .menu-item .menu-link.active .menu-icon .svg-icon,
.aside-dark .menu .menu-item .menu-link.active .menu-icon i {
  color: #fff;
}

.aside-dark .menu .menu-item .menu-link.active .menu-icon,
.aside-dark .menu .menu-item .menu-link.active .menu-icon .svg-icon,
.aside-dark .menu .menu-item .menu-link.active .menu-icon i:hover {}

.aside-dark .menu .menu-item.hover:not(.here)>.menu-link:not(.disabled):not(.active):not(.here) .menu-icon,
.aside-dark .menu .menu-item.hover:not(.here)>.menu-link:not(.disabled):not(.active):not(.here) .menu-icon .svg-icon,
.aside-dark .menu .menu-item.hover:not(.here)>.menu-link:not(.disabled):not(.active):not(.here) .menu-icon i,
.aside-dark .menu .menu-item:not(.here) .menu-link:hover:not(.disabled):not(.active):not(.here) .menu-icon,
.aside-dark .menu .menu-item:not(.here) .menu-link:hover:not(.disabled):not(.active):not(.here) .menu-icon .svg-icon,
.aside-dark .menu .menu-item:not(.here) .menu-link:hover:not(.disabled):not(.active):not(.here) .menu-icon i {
  color: #636480;
}

.aside-dark .menu .menu-item.hover:not(.here)>.menu-link:not(.disabled):not(.active):not(.here),
.aside-dark .menu .menu-item:not(.here) .menu-link:hover:not(.disabled):not(.active):not(.here) {
  background: #28283b;
}

.header-tablet-and-mobile-fixed .wrapper,
.header-tablet-and-mobile-fixed.toolbar-tablet-and-mobile-fixed .wrapper,
.wrapper {
  padding-top: 0;
}


.aside-dark .menu .menu-item.show>.menu-link {
  background-color: #5d5fef;
  margin-bottom: 10px
}

.aside-dark .menu .menu-item.show>.menu-link .menu-icon,
.aside-dark .menu .menu-item.show>.menu-link .menu-icon .svg-icon,
.aside-dark .menu .menu-item.show>.menu-link .menu-icon i {
  color: #fff;
}

.aside-menu .menu .menu-item .menu-link,
.aside-menu .menu .menu-item .menu-content {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.w-600 {
  max-width: 100%;
  width: 600px;
}

.modal-footer.flex-center {
  align-items: center;
  justify-content: center;
}

.content-login {
  width: 55%;
}

.register-wrapper {
  height: 100vh;
  overflow: 'auto';
}

.register-wrapper form {
  /* height: 100vh; */
  overflow: auto;
  /* padding: 90px */
}

.illustration-login {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 !important;
}

@media (max-width: 990px) {
  .hidden-mobile {
    display: none !important;
  }

  .auth-container {
    padding: 40px !important;
  }

  .register-wrapper {
    padding: 40px;
    height: auto;
    overflow: visible;
  }

  .register-wrapper form {
    height: auto;
    overflow: hidden;
    padding: 0px
  }

  .content-login {
    width: 100%;
  }
}





:focus-visible {
  outline: none
}

.dividers {
  background: #EFF2F5;
  height: 1px;
  width: 100%;
}

/*  */
.page-item:hover:not(.offset) .page-link {
  background: #bbe6ff;
  color: #00A3FF;
}

.page-item.active .page-link {
  background: #00A3FF;
  color: #fff;
}

.table-challenge {
  position: relative;
  background: #F3F6F9;
}

.container {
  padding-left: 0 !important;
  padding-right: 0 !important;
}



.page-link.active {
  background: #00A3FF;
  color: #fff;
}

.svg-icon-blue,
.svg-icon.svg-icon-blue {
  color: #00A3FF;
}

.badge-blue-primary {
  color: #009EF7;
  background-color: #F1FAFF;
}

.input-group.input-group-solid .input-group-text {
  border-color: initial;
}

/* .input-group-text {
    border: none;
    border-right: 2px solid #B5B5C3 !important;
} */

.bg-gray-disabled {
  background: #464E5F;
}

.text-capitalize {
  text-transform: capitalize;
}

@media (min-width: 992px) {
  .container-metronic {
    width: calc(100% - 265px);
    margin-left: auto;
    margin-bottom: 5rem;
  }
  .authTokenUrl {
    width: 100%;
    margin-top:50px;
    margin-bottom: 5rem;

  }
}

.badge-light-blue {
  background: #f1faff;
  color: #009ef7;
}

.btn-ciclo {
  background: rgba(245, 248, 250, 0.4);
  border: 1px dashed #939393;
  border-radius: 4px;
  padding: 10px 20px;
  margin-right: 10px;
}

input[type=radio]:checked~.btn-ciclo {
  border: 1px #009EF7 dashed !important;
  color: #009EF7 !important;
}

.form-ciclo {
  border: none;
  border-bottom: 2px solid #009EF7;
  width: 50px;
  padding: 10px 0 5px 10px;
  color: #a1a5b7;
}

.form-ciclo::placeholder {
  text-align: end;
}


.border-0 {
  border-width: 0 !important;
}

.border-primary-left-20 {
  border-left: 15px solid #009EF7;
}


.btn-copy button {
  visibility: hidden;
  transition: all 100ms;
}

.btn-copy:hover button {
  visibility: visible;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%;
  /* 720 / 1280 = 0.5625 */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.video+div {
  width: initial !important;
  height: initial !important;
}

.settings-h1 {
  font-size: 32px !important;
  line-height: 44px;
}

.tooltip .tooltip-inner {
  background-color: #181c32;
  color: #fff;
}

.tooltip .tooltip-arrow:before {
  border-top-color: #181c32;
}

.font-roboto {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
}

.grid-forgot-password {
  display: grid;
  grid-template-columns: calc(100% - 160px) 150px;
  align-items: center;
}

.grid-radio {
  max-width: 650px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}

.border-primary-label {
  z-index: 9999;
}

.border-primary-label {
  background: rgba(245, 248, 250, 0.4);
  border: 1px dashed #B5B5C3;
  border-radius: 8px;
  padding: 15px 15px 15px 45px;
  width: 100%;
}

.input-radio {
  position: absolute;
  top: 30%;
  left: 15px;
}

input[type=radio]:checked~.border-primary-label {
  z-index: 0;
  background: #F1FAFF;
  border: 1px dashed #009EF7;
}

.grid-radio-type {
  max-width: 650px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}

.tip {
  height: 1.2rem;
  margin-left: 0.1rem;
  /* stroke: #00A3FF; */
}

.scroll-view {
  width: 100%;
  overflow: scroll;
}

rect {
  /* fill: #00A3FF; */
  opacity: 0.3;
  stroke: transparent;
}

input[type="date"] {
  text-transform: uppercase;
}


input[type="date"] {
  text-transform: uppercase;
}

[disabled] {
  opacity: 0.6 !important;
}

.font-14{
  font-size: 14px;
}

.divisor{
  width: 100%;
  margin: 0 auto;
  height: 1px;
  background: #EBEDF3;
}

.border-l{
  border: none;
  border-left: 20px solid;
}

.is-invalid + .invalid-feedback{
  display: block;
}

.pagination{
  justify-content: initial !important
}

.page-link.setas{
  color: #009ef7 !important;
  background: #f1faff !important;
}
.page-link.setas:hover{
  color: #ffffff !important;
  background: #009EF7 !important;
}
.page-item.disabled{
  opacity: .5;
  filter: grayscale(100%);
}

.checkbox {
  width: 20px;
  height: 20px;

  border: 1px solid #939393;
  border-radius: 16px;
}

.closeIcon {
  filter: invert(50%) sepia(60%) saturate(4000%) hue-rotate(165deg) brightness(100%) contrast(100%) !important
}

.MultiSelect.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating), .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3), .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control, .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  width: calc(100% - 20px);
}


.search-icon{
  background:url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC90lEQVR4nO1YS2sUQRDuKHpT1Iivo3ry7uNHaKLgYHSna6dqZMGjmqRqorCQq+J/CIgmR83ZF3iMj4Og8YEgJOhNj6sRpRYPsz2z7CM9m6zMB82w7MzXVV3vNqZEiRIlhhbWyigggyVZsChLFmUFiBv6bP4mWdD/4/jqHrOZEMZyFIjnAfkXkPzpuPQ9lPsAk0c2VPAgqG+3JHcsys+uBM8sbgDJ7Vqttm1D3MUiP+5P8NZlkZ+H4fV9AxP+Yu3aXkD+0OZUX0AkSRTJ8Upt5qCerj4hnj4BxDOA8jJXCZL3yjsYt0F5lvVreRfGfKYbDiAZA+TlrCXkSeHupD6f4wIPiKZ29MKj7wPxYg7XrUKzjRuwKny9Xt/SD59+l1WCG4hy2L/0TdPzvOs2vZ68i0qlvlP9vzUe+J4pqEi15HmLctoLd8Tjbp2wVkaNT2gFdbONX34nOyGHvoN3oWWDSBKv/Cg3nAOaN543WEpvoHneJ3+IctKJg7e+XWg1vcFEnOz3yU80dcixwA/fGaiR3kALmk9+5QMnkH270Ep6A20PCrUA8tdCY0B7m0JjAOVNsVmIeMYnPxDfdCr83WGqAyNA8jrNH2ISe+Q3RsfAnIlrzAc3IJ9z+6FqdfKAD+4OvRAvay+zHs5qtb7Lknx0ZoM5UwR0hnXTKaA87LcbDYJgq37vnn4Fk2OmKOgMmzOFLfZqCT35rPCiXLOmSOjEBMRP80bCkJKzXVCMqM9bkk+5IykymKKhs6vbw6d6mFfamGle1+KkFVaLXvXy9Kl/DVtLtslZayFNTxSuRHM+IHnUQZh+15r3drqdO+kMmwnsrpd+x7M2lmpTaGrthQZiCYXOsDoGdn0zp9eNJHPpbKPCgvv9IJVQXLoiu9X0Wi+0n7co3y3ybyD5pr2NtgdaYdsVqXAzKLFe2FiCHEsOJiZ8wf4PSgAm54deibBNTOg1jBkW2EguZO6jiL+YYYJ1lUBeNcMGG/G4Jfmsp+9rDilRokQJ4x1/Ad0zsG8f9/qJAAAAAElFTkSuQmCC') 97% center no-repeat;
  background-size: 8%;
}

.input-menu{
  width: 100%;
  height: 32px;
  padding: 0 20px;
  background: #1e1e2d;
  border: 1px solid rgba(44, 44, 65, 0.7);
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
  color: rgba(255, 255, 255, 0.6);
 
}

.input-menu.active{
  filter: blur(5px);
  border: none;
}
.btn-menu{
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  height: 32px;
  color: rgba(255, 255, 255, 0.6);
  background: #1e1e2d;
  border: 1px solid rgba(44, 44, 65, 0.7);
  padding: 0 15px;
  font-size: 20px;
 
}

.btn-menu.active{
  border: none;
}

.BsThreeDotsVertical.dropdown-toggle::after{
  content: none !important;
}