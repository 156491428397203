.toolbarClassName {
  background-color: #d4d9de;
  border: 1px solid #ccc;

  .rdw-option-wrapper {
    padding: 0.9rem 0.45rem;
    border-radius: 0.5rem;
  }
}

.wrapperClassName {
  background-color: #F3F6F9;

  border: 1px solid #ccc;
  border-radius: 6px;
}

.editorClassName {
  background-color: #F3F6F9;
  // background-color: #fdf2f2;
  min-height: 10rem;

  padding: 0.5rem 1rem;
}

.is-invalid {
  background-color: #fdf2f2 !important;
}

.is-valid {
  background-color: #f4fbf7 !important;
}
