.action-modal-header {
  span {
    padding-left: 0.6rem;
    font-size: 18px;
  }

  .approved {
    color: #009EF6;
    font-weight: 400;
  }

  .reproved {
    color: #FF4B55;
    font-weight: 600;
  }

  .success {
    color: #50cd89;
    font-weight: 600;
  }
}