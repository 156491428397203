.check-container {
  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    
    & + span {
      position: relative;
      cursor: pointer;
      padding: 0;
    }
    
    & + span::before {
      content: '';
      display: inline-block;
      vertical-align: text-top;
      width: 21px;
      height: 21px;
      background: #F3F6F9;
      border: 0.5px solid #B5B5C3;
      border-radius: 6px;
    }

    &:checked ~ span::before {
      background: #00A3FF;
      border-color: #00A3FF;
    }
    
    
    &:checked + span::after {
      content: '';
      position: absolute;
      left: 5px;
      top: 9px;
      background: white;
      width: 2px;
      height: 2px;
      box-shadow:
        2px 0 0 white,
        4px 0 0 white,
        4px -2px 0 white,
        4px -4px 0 white,
        4px -6px 0 white,
        4px -8px 0 white;
      transform: rotate(45deg);
    }
  }
}