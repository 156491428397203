@import "./components/Review/styles.scss";
@import "../../pages/Challenge/Sales/pages/view/components/Modals/styles.scss";

table#table-component {
  width: 100%;

  @mixin border {
    border: 1px solid red;
  }

  @mixin cell {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 10px;
  }

  & {
    //@include border;
    border-collapse: collapse;
  }

  thead {
    & > tr.divider {
      border-bottom: 1px solid #B5B5C3;
    }
  }

  tbody {
    & > tr.clickable {
      cursor: pointer;
    }

    & > tr:hover,
    & > tr.selected {
      background: #F1F1F1;
    }

    & > tr.divider {
      border-bottom: 1px dashed #B5B5C3;
    }
  }

  tr > th,
  tr > td {
    padding: 10px;
  }

  th > div.table-header {
    //@include border;
    @include cell;

    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #B5B5C3;


    > .chevron {
      margin-left: 10px;
      opacity: 0;

      &.sorted {
        opacity: 1;
      }

      &.chevron-desc {
        transform: rotate(0deg);
      }

      &.chevron-asc {
        transform: rotate(180deg);
      }
    }
    &.static {
      pointer-events: none;
    }

    &.sortable {
      cursor: pointer;
    }
  }

  td > div.table-data {
    //@include border;
    @include cell;
  }


  td > div.collapse-table-data {
    //@include border;
    @include cell;
    padding: 0px !important;
  }

  .chevron-down {

  }

  .accordionButton {
    background: transparent;

    &:hover {
      background-color: transparent;
    }
  }
}