.challenge {
  width: 100%;
  min-width: 130px;

  @media screen and (min-width: 1440px) {
    min-width: 200px;
  }
}

.progress-info {
  display: grid;
  text-align: left;

  @media screen and (min-width: 1440px) {
    display: flex;
    justify-content: space-between;
  }

  span {
    font-weight: 500;
    font-size: 12px;
  }
}

.orange .progress-bar {
  background-color: #FF810C !important;
}

.yellow .progress-bar {
  background-color: #FFC121 !important;
}

.blue .progress-bar {
  background-color: #009EF6 !important;
}

.red .progress-bar {
  background-color: #F1416C !important;
}

.green .progress-bar {
  background-color: #50CD89 !important;
}