.chartContainer {
  background-color: #F5F8FA;

  display: flex;
  flex-direction: column;
  justify-content: space-around;

  .date {
    color: #B5B5C3;
    font-size: 0.625rem;
    line-height: 0.9375rem;
    text-align: right;
    width: 100%;
    margin-bottom: 0.625rem;
  }

  .avatarContainer {
    width: 4.125rem;
    height: 4.125rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    background: white;

    margin-bottom: 0.09375rem;

    >p {
      margin: 0;
      font-size: 1.8rem;
      font-weight: bold;
      color: #00A3FF;
    }
  }

  .userName {
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.875rem;
    color: #181C32;

    margin-bottom: 0.625rem;
  }

  .socialsUsername {
    display: flex;
    align-items: center;

    .atIcon {
      margin-right: 0.260625rem;
    }


    font-weight: 500;
    font-style: italic;
    font-size: 0.625rem;
    line-height: 0.9375rem;
    color: #B5B5C3;

    margin-bottom: 0.625rem;
  }

  .socialStats {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 0.625rem 0;

    .followersNumber {
      font-size: 2rem;
      font-weight: 600;
      line-height: 3rem;
      color: #3F4254;
    }

    .followersLabel {
      font-size: 0.9375rem;
      line-height: 1.40625rem;
      font-weight: 400;
    }
  }

  .buttonWrapper {
    display: flex;
    gap: 1.0625rem;

    button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0.625rem 0.75rem;
      height: 2.375rem;
      // width: 5.9375rem;

      border: none;
      border-radius: 0.375rem;

      background: #F1416C;
      color: #FFFFFF;
      font-weight: 700;
      font-size: 0.75rem;
      line-height: 1.125rem;
      cursor: pointer;
    }

    .approveButton {
      background: #1DC894;
    }

    .approvedStatusButton {
      background: transparent;
      border: 1px solid #1DC894;
      color: #1DC894;
      cursor: default;
    }

    .declinedStatusButton {
      background: transparent;
      border: 1px solid #F1416C;
      color: #F1416C;
      cursor: default;
    }
  }
}

.approvedStatus {
  background: #E8FFF3;
}

.declinedStatus {
  background: #FFF5F8;
}

.approvedTxt {
  color: #5CDA95 !important;
  font-weight: 700;
  font-size: 14px;
}

.declinedTxt {
  color: #FE85A2 !important;
  font-weight: 700;
  font-size: 14px;
}