.profileContainer {
  width: calc(100% - 265px);
  margin-left: auto;
  margin-bottom: 5rem;

  .profileBackground {
    background: #E4E6EF;
    height: 245px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .formWrapper {
    background: #FFFFFF;
    max-width: 47.6rem;
    padding: 2rem;
    margin: 0 auto;
    border-radius: 12px;
    margin-top: -4rem;

    .completeProfileWrapper {
      display: flex;
      background: #FFF7E7;
      border: 1px solid #FFA800;
      border-radius: 6px;
      padding: 1.2rem;
      margin-bottom: 2rem;

      .attentionIcon {
        filter: invert(88%) sepia(31%) saturate(5381%) hue-rotate(326deg) brightness(99%) contrast(97%);
        margin-right: 1rem;
      }

      .completeProfileTitle {
        margin: 0;
        font-weight: bold;
        color: #3F4254;
      }

      .completeProfileDescription {
        margin: 0;
        font-weight: 500;
        color: #7E8299;
      }
    }



    .containerTwoInputs {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .formInput {
        label {
          width: 100%;
        }

        input {
          width: 21rem;
        }

        select {
          width: 22rem;
        }
      }

    }
  }

}


.socialMediaContainer {
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 1.9rem;
  min-width: 7rem;

  .socialMediaTitle {
    color: #3F4254;
    font-size: 12px;
    margin: 0 0.5rem;
  }
}

.snackbar {
  background-color: #333;
  position: fixed;
  bottom: 0;
  left: 60%;
  width: 400px;
  margin-left: -200px;
  opacity: 0;
  visibility: hidden;
  color: #fff;
  padding: 16px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 200ms ease-in-out;
}

.snackbar .close {
  font-size: 18px;
  cursor: pointer;
  transition: 200ms;
  padding: 0;
  border: 0;
  background-color: transparent;
  color: #fff;
}

.snackbar.visible {
  opacity: 1;
  bottom: 20px;
  visibility: visible;
}

.snackbar.success {
  background-color: #00A3FF;
}

.statusWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .statusTitle {
    font-weight: bold;
    color: #3F4254;
    margin: 0
  }

  .statusDescription {
    font-size: 10px;
    color: #B5B5C3;
    margin: 0;
  }
}

.button {
  border-radius: 6px;
  border: none;
  padding: 12px 20px;
  color: #FFFFFF;
}

.tip {
  height: 1.2rem;
  margin-left: 0.1rem;
  stroke: #00A3FF;

  rect {
    fill: #00A3FF;
    opacity: 0.3;
    stroke: transparent;
  }
}

.titleHeaderSocialMedia {
  color: #B5B5C3;
  font-size: 12px;
  font-weight: 600;
  margin: 0;
  height: 100%;
}