.buttonText {
  color: #5D5FEF;

  &:hover {
    color: #5D5FEF;
    text-decoration: underline;
  }

  &:disabled {
    color: #B5B5C3;
  }
}