.socialMediaContainer {
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 1.9rem;
  min-width: 7rem;

  .socialMediaTitle {
    color: #3f4254;
    font-size: 12px;
    margin: 0 0.5rem;
  }
}

