.action-modal-header {
  span {
    padding-left: 0.6rem;
    font-size: 18px;
  }

  .info-text {
    font-size: 14px;
  }

  .reward {
    color: #009EF6;
    font-weight: 400;
  }

  .success {
    color: #50cd89;
    font-weight: 600;
  }

  .errored {
    color: #F1416C;
    font-weight: 600;
  }
}