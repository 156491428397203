button.custom-btn-approved {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px;
  min-width: 26px;
  padding: 5px;
  overflow: hidden;
  border-radius: 6px;
  border: none;

  background-color: #009EF6;

  &:disabled {
    pointer-events: none;
    background-color: #D4E9F4;
  }
}

button.custom-btn-reproved {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px;
  min-width: 26px;
  padding: 5px;
  overflow: hidden;
  border-radius: 6px;
  border: none;

  background-color: #F1416C;

  &:disabled {
    pointer-events: none;
    background-color: #EBD6DB;
  }
}

button.custom-btn-reward {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px;
  min-width: 26px;
  padding: 5px;
  overflow: hidden;
  border-radius: 6px;
  border: none;

  background-color: #50CD89;

  &:disabled {
    pointer-events: none;
    background-color: #D0EDDD;
  }
}
