.tag-container {
  display: flex;
  flex-wrap: wrap;

  width: 100%;
  padding: 1rem;

  border-color: 1px solid #B5B5C3;
  border-radius: 4px;
  background-color: #f5f8fa;
}

.tag-container-out {
  display: flex;
  flex-wrap: wrap;

  width: 100%;
  max-height: 15rem;
  overflow: auto;
}

.tag-container input {
  border-color: transparent;
}

.tag {
  display: flex;
  align-items: center;

  margin: 7px 0;
  margin-right: 10px;
  padding: 0 10px;
  padding-right: 5px;
  border: 1px solid #C9E8FF;
  border-radius: 6px;

  background-color: #C9E8FF;
  white-space: nowrap;
  color: #009EF6;
  font-weight: 500;

  transition: 0.5s;

  &:hover {
    background-color: #bee3ff;
  }
}

.tag button {
  display: flex;

  padding: 6px 6px 6px 12px;
  border: none;
  background-color: unset;

  cursor: pointer;

  color: #009EF6;
  font-weight: 600;
}

.loadingTag {
  display: flex;
  justify-content: start;
  padding: 1rem 0;
}